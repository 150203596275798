input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.margin-top-20 {
    margin-top: 20px;
}

.margin-top-10 {
    margin-top: 10px !important;
}

.margin-top-5 {
    margin-top: 5px;
}

.margin-bottom-10 {
    margin-bottom: 10px !important;
}

@font-face {
    font-family: avenir-bold;
    src: url(../assets/fonts/avenir-bold.otf);
}

@font-face {
    font-family: avenir-reg;
    src: url(../assets/fonts/avenir-reg.otf);
}

@font-face {
    font-family: bentonsans-Medium;
    src: url(../assets/fonts/bentonSans-Regular.otf);
}

.avenir-bold {
    font-family: avenir-bold !important;
}

.benton-sans-font {
    font-family: bentonsans-Medium;
}

#root {
    margin: 0;
}

body {
    font-family: avenir-reg;
    margin: 0px;
    height: 100%;
    width: 100%;
    background-color: #051315;
}

.flex {
    display: flex;
    justify-content: space-between;
}

.cus-label {
    color: white;
}

.subTitle {
    color: #727A7B;
}
.subTitle1 {
    color: #727A7B;
    margin-bottom: 40px;
}

.white {
    color: #fff;
}

.fa-android {
    font-size: 3em;
}

.white-color {
    color: white !important;
}

.MuiFormLabel-asterisk {
    color: red !important;
}

h1 label {
    display: block !important;
    font-size: 2em !important;
    margin-block-start: 0.67em !important;
    margin-block-end: 0.67em !important;
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
    font-weight: bold !important;
}

h2 label {
    display: block !important;
    font-size: 1.5em !important;
    margin-block-start: 0.83em !important;
    margin-block-end: 0.83em !important;
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
    font-weight: bold !important;
}

h3 label {
    display: block !important;
    font-size: 1.17em !important;
    margin-block-start: 1em !important;
    margin-block-end: 1em !important;
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
    font-weight: bold !important;
}

h4 label {
    display: block !important;
    margin-block-start: 1.33em !important;
    margin-block-end: 1.33em !important;
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
    font-weight: bold !important;
}

h5 label {
    display: block !important;
    font-size: 0.83em !important;
    margin-block-start: 1.67em !important;
    margin-block-end: 1.67em !important;
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
    font-weight: bold !important;
}

h6 label {
    display: block !important;
    font-size: 0.67em !important;
    margin-block-start: 2.33em !important;
    margin-block-end: 2.33em !important;
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
    font-weight: bold !important;
}

.gridContainer {
    width: 90.5%;
    padding-left: 0.75%;
    padding-right: 0.75%;
    margin: auto;
    clear: none;
    float: none;
    margin-left: auto;
}

label {
    letter-spacing: 0.1em !important;
    font-size: 12px !important;
    text-transform: uppercase !important;

}

.font-transform {
    text-transform: none !important;
}

.font-size-16 {
    font-size: 16px !important;
}

.font-size-14 {
    font-size: 14px !important;
}

.active-btn {
    background-color: #0BD7EE !important;
    color: #000 !important;
}

.deactive-btn {
    background-color: #727A7B !important;
    color: #fff !important;
}

.yellow-font {
    color: #E29C2E !important;
}

.padding-top-30 {
    padding-top: 30px;
}

.padding-top-10 {
    padding-top: 10px;
}

.bg-transparent {
    background-color: transparent;
}

.border-none {
    border: 0px
}

.w-100 {
    width: 100% !important;
}

.delete-button {
    width: 150px !important;
    background-color: #E29C2E !important;
    color: #fff !important;

    @media only screen and (max-width: 481px) {
        width: 50px !important;
    }
}

.delete-button:hover,
.delete-button:focus {
    background-color: #E29C2E !important;
}

.delete-clear-button {
    background-color: #E29C2E !important;
    color: #fff !important;
}

.delete-clear-button:hover,
.delete-clear-button:focus {
    background-color: #E29C2E !important;
}

.cursor-pointer {
    cursor: pointer;
}

.inner-progress-header__fixed--bottom--left .header-title {
    margin: 0px !important;
}

.inner-progress-header__fixed--bottom--right {
    h4 {
        margin: 0px !important;

        label {
            margin: 0px !important;
        }
    }
}

.progress-footer {
    .gridContainer {
        margin: 0px 129px;
        width: unset;
        padding-left: unset;
        padding-right: unset;

        @media screen and (max-width: 992px) {
            margin: 0px 17px;
        }
    }
}

.login_form.signup-form h4 .yellow-font {
    font-size: 20px;
    line-height: 1;
}
div.login-container .login_form .or-condition,
div.login-container .login_form .PhoneInput,
div.login-container .login_form .remember-container{
    display:none;
}
.signup-form .PhoneInput{
    display: flex !important;
}
